import { rest } from '@/api/fetcher';

export interface SigninRequest {
  id: string;
  password: string;
  mfa_code?: string | null;
}

export interface SigninResponse {
  code: 'SUCCESS' | 'MFA_LOGIN' | 'FAILED';
}

export const signin = (data: SigninRequest) =>
  rest<SigninResponse>({
    url: '/login',
    method: 'post',
    data,
  });

interface SSORequest {
  shop_id: string;
}

export const sso = (data: SSORequest) =>
  rest<string>({
    method: 'post',
    url: '/auth/token',
    responseType: 'text',
    data,
  });

export interface User {
  id: string;
  uuid: string;
  identifier: string;
  date_last_access: number;
  name?: string;
  phone_number?: string;
  agency_id?: string;
  role: 'AGENCY' | 'ZIGZAG';
  agency_account_type?: 'MANAGER' | 'MARKETER';
  is_sub_agency: boolean;
  mfa_method?: 'SMS' | null;
}

export const fetchUser = async () =>
  rest<User>({
    url: '/user',
    method: 'get',
  });

export const logout = () =>
  rest<void>({
    url: '/logout',
    method: 'post',
  });

export interface CognitoSigninUrlRequest {
  redirect_url: string;
}

export const fetchCognitoSigninUrl = (data: CognitoSigninUrlRequest) =>
  rest<string>({
    url: '/cognito_url',
    method: 'post',
    data,
  });

export interface SigninByCognitoRequest {
  code: string;
  redirect_url: string;
}

export const signinByCognito = (data: SigninByCognitoRequest) =>
  rest<string>({
    url: '/loginCognito',
    method: 'post',
    data,
  });
