import { BaseText, FormField, semantic_colors, VStack } from '@croquiscom/pds';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { UpdateUserFormValues } from '../UpdateUserFormProvider';
import { PasswordInput } from '@/components/common';

export const ConfirmNewPassword: FC = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<UpdateUserFormValues>();

  const error = errors.new_password_confirm?.message;
  const is_change_password = watch('is_change_password');
  if (!is_change_password) {
    return null;
  }

  return (
    <FormField label='비밀번호 확인'>
      <VStack spacing={4}>
        <PasswordInput
          {...register('new_password_confirm')}
          size='medium'
          maxLength={100}
          placeholder='비밀번호를 확인 해 주세요'
        />
        {error && (
          <BaseText kind='Caption_11_Regular' color={semantic_colors.state.negative}>
            {error}
          </BaseText>
        )}
      </VStack>
    </FormField>
  );
};
