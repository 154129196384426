import { BaseText, Button, ButtonProps, IconExcel, semantic_colors, VStack } from '@croquiscom/pds';
import { forwardRef } from 'react';
import { Tooltip } from '@/components/common';

export const ExcelButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ startIcon = <IconExcel />, endIcon = tooltip, kind = 'outlined_secondary', size = 'small', ...rest }, ref) => {
    return (
      <Button {...rest} ref={ref} kind={kind} size={size} startIcon={startIcon} endIcon={endIcon}>
        엑셀 다운로드
      </Button>
    );
  },
);

const tooltip = (
  <Tooltip
    color={semantic_colors.content.tertiary}
    content={
      <VStack p={4}>
        <BaseText kind='Caption_11_SemiBold'>Edge 브라우저에서 다운로드 오류 발생 시 설정을 변경해주세요.</BaseText>
        <BaseText kind='Caption_11_SemiBold'>
          - 설정 &gt; 다운로드 &gt; '브라우저에서 Office 파일 열기' 옵션 Off 처리
        </BaseText>
      </VStack>
    }
  />
);
