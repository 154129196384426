import { BaseText, FormField, semantic_colors, VStack } from '@croquiscom/pds';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { UpdateUserFormValues } from '../UpdateUserFormProvider';
import { PasswordInput } from '@/components/common';

export const NewPassword: FC = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<UpdateUserFormValues>();

  const error = errors.new_password?.message;
  const is_change_password = watch('is_change_password');
  if (!is_change_password) {
    return null;
  }

  return (
    <FormField label='신규 비밀번호'>
      <VStack spacing={4}>
        <PasswordInput
          {...register('new_password')}
          size='medium'
          maxLength={100}
          placeholder='변경할 비밀번호를 입력 해 주세요'
        />
        <ul>
          <li>
            <BaseText kind='Caption_11_Regular' color={semantic_colors.content.secondary}>
              - 비밀 번호는 1개 이상의 숫자, 대소문자 및 특수 문자를 포함해야 합니다.
            </BaseText>
          </li>
          <li>
            <BaseText kind='Caption_11_Regular' color={semantic_colors.content.secondary}>
              - 비밀번호는 10자 이상으로 설정 해 주세요
            </BaseText>
          </li>
        </ul>
        {error && (
          <BaseText kind='Caption_11_Regular' color={semantic_colors.state.negative}>
            {error}
          </BaseText>
        )}
      </VStack>
    </FormField>
  );
};
