import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { User } from '@/api/rest';
import { useAgencyId, useUser } from '@/common/hooks';

interface Props {
  scopes?: User['agency_account_type'][];
}

export const ResourceGuard: FC<Props> = ({ scopes = ['MANAGER', 'MARKETER'] }) => {
  const { user } = useUser();
  const { agency_id } = useAgencyId();
  const is_zigzag_account = user?.role === 'ZIGZAG';
  const is_access_granted = user?.agency_id === agency_id && scopes.includes(user?.agency_account_type);
  if (is_zigzag_account || is_access_granted) {
    return <Outlet />;
  }
  return <Navigate to='/404' replace />;
};
