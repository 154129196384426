import 'reflect-metadata/lite';
import * as Sentry from '@sentry/react';
import { FC } from 'react';
import { QueryClientProvider, ThemeProvider } from '@/common/context';
import { mode, sentry_dsn } from '@/common/env';
import { RootRouter } from '@/routes';

Sentry.init({
  dsn: sentry_dsn,
  environment: mode,
});

export const App: FC = () => {
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <Sentry.ErrorBoundary>
          <RootRouter />
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
