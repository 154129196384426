import { Modal, useToast } from '@croquiscom/pds';
import { GraphQLError } from 'graphql/index';
import { FC } from 'react';
import { Form, UpdateUserFormProvider, UpdateUserFormValues } from './Form';
import { useChangeSellerAccountInfoMutation } from '@/api';
import { useUser } from '@/common/hooks';

interface Props {
  opened?: boolean;
  close?: () => void;
}

export const UpdateUserModal: FC<Props> = ({ opened, close }) => {
  const toast = useToast();
  const { user, refetch } = useUser();
  const { mutate, isLoading: is_loading } = useChangeSellerAccountInfoMutation({
    onSuccess: ({ change_seller_account_info }) => {
      if (change_seller_account_info) {
        toast.show({
          kind: 'success',
          position: 'bottom-center',
          content: '사용자 정보가 수정 되었습니다',
        });
        return refetch();
      }
      toast.show({
        kind: 'error',
        position: 'bottom-center',
        content: '사용자 정보가 수정 도중 문제가 발생 하였습니다',
      });
    },
    onError: (error) => {
      toast.show({
        kind: 'error',
        position: 'bottom-center',
        content: error instanceof GraphQLError ? error.message : '사용자 정보가 수정 도중 문제가 발생 하였습니다',
      });
    },
  });

  const handleSubmit = (values: UpdateUserFormValues) => {
    mutate({
      input: {
        name: values.name,
        phone_number: values.tel.replace(/\D/g, ''),
        mfa_method: values.mfa === 'SMS' ? 'SMS' : undefined,
        current_password: values.password,
        new_password: values.is_change_password ? values.new_password : undefined,
        seller_account_id: user?.id ?? '',
      },
    });
  };

  return (
    <Modal
      closeButton
      title='사용자 정보 수정'
      opened={opened}
      onCancel={close}
      contentProps={{ scrollable: true, p: 0 }}
    >
      <UpdateUserFormProvider
        defaultValues={{
          email: user?.identifier,
          name: user?.name,
          tel: user?.phone_number,
          mfa: (user?.mfa_method ? user?.mfa_method : 'NONE') as UpdateUserFormValues['mfa'],
        }}
      >
        <Form loading={is_loading} onCancel={close} onSubmit={handleSubmit} />
      </UpdateUserFormProvider>
    </Modal>
  );
};
