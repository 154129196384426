import { forwardRef, PropsWithChildren, useImperativeHandle } from 'react';
import { useForm, UseFormReturn, FieldValues, DefaultValues, FormProvider, Resolver } from 'react-hook-form';

interface BaseProps<T extends FieldValues> {
  defaultValues?: DefaultValues<T>;
  resolver?: Resolver<T>;
}

interface FormContextBaseProps<T extends FieldValues> extends BaseProps<T>, PropsWithChildren {}

export const createFormContext = <T extends FieldValues>(options?: BaseProps<T>) =>
  forwardRef<UseFormReturn<T>, FormContextBaseProps<T>>(
    ({ defaultValues = options?.defaultValues, resolver = options?.resolver, children }, ref) => {
      const form = useForm<T>({ defaultValues, resolver });
      useImperativeHandle(ref, () => form, [form]);
      return <FormProvider {...form}>{children}</FormProvider>;
    },
  );
