import { BaseText, FormField, Input, semantic_colors, VStack } from '@croquiscom/pds';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { UpdateUserFormValues } from '../UpdateUserFormProvider';

const message = {
  required: '담당자 명을 입력 해 주세요',
};

export const Name: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<UpdateUserFormValues>();
  const error = errors.name?.message;
  return (
    <FormField label='이름'>
      <VStack spacing={4}>
        <Input
          {...register('name', { required: message.required })}
          size='medium'
          placeholder={message.required}
          maxLength={100}
        />
        {error && (
          <BaseText kind='Caption_11_Regular' color={semantic_colors.state.negative}>
            {error}
          </BaseText>
        )}
      </VStack>
    </FormField>
  );
};
