import { css } from '@emotion/react';
import { theme } from '@/common/context/ThemeProvider/theme';
import { flex } from '@/common/styles/index';

export const normalize = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    color: ${theme.palette.content.primary};
    font-family: Pretendard, system-ui, sans-serif;
  }

  ul,
  ol {
    list-style: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:active {
    color: inherit;
  }

  #root {
    ${flex({ direction: 'column' })}
    min-height: 100vh;
  }

  @supports (min-height: 100dvh) {
    #root {
      min-height: 100dvh;
    }
  }
`;
