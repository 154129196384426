import { FormField, Input } from '@croquiscom/pds';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { UpdateUserFormValues } from '../UpdateUserFormProvider';

export const Email: FC = () => {
  const { register } = useFormContext<UpdateUserFormValues>();
  return (
    <FormField label='아이디'>
      <Input {...register('email')} readOnly size='medium' />
    </FormField>
  );
};
