import { Badge } from '@croquiscom/pds';
import { css } from '@emotion/react';
import { FC } from 'react';

interface Props {
  score: number;
}

export const Chip: FC<Props> = ({ score }) => {
  let text = '우수';
  if (score >= 5 && score < 6) {
    text = '주의';
  }
  if (score >= 6 && score < 8) {
    text = '경고';
  }
  if (score >= 8) {
    text = '위험';
  }

  return (
    <Badge
      css={css`
        color: inherit;
      `}
      kind='fill'
      size='small'
      color='blue'
    >
      {text}
    </Badge>
  );
};
