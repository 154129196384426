import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  IsBoolean,
  IsEmail,
  IsIn,
  IsMobilePhone,
  IsNotEmpty,
  IsOptional,
  IsStrongPassword,
  ValidateIf,
} from 'class-validator';
import { createFormContext } from '@/common/context';
import { IsSameAs } from '@/common/validator';

export class UpdateUserFormValues {
  @IsEmail(undefined, { message: '올바른 이메일이 아닙니다' })
  @IsNotEmpty({ message: '이메일을 입력 해 주세요' })
  email: string;

  @IsNotEmpty({ message: '담당자 명을 입력 해 주세요' })
  name: string;

  @IsMobilePhone(undefined, undefined, { message: '올바른 연락처가 아닙니다' })
  @IsNotEmpty({ message: '담당자 연락처를 입력 해 주세요' })
  tel: string;

  @IsIn(['SMS', 'NONE'], { message: '올바른 인증 방식이 아닙니다' })
  @IsNotEmpty({ message: '이차인증 방식을 선택 해 주세요' })
  mfa: 'SMS' | 'NONE';

  @IsNotEmpty({ message: '비밀번호를 입력 해 주세요' })
  password: string;

  @IsOptional()
  @IsBoolean()
  is_change_password: boolean;

  @ValidateIf((object) => !!object?.is_change_password)
  @IsStrongPassword({ minLength: 10 }, { message: '올바른 형식의 비밀번호가 아닙니다' })
  @IsNotEmpty({ message: '비밀번호를 입력 해 주세요' })
  new_password: string;

  @ValidateIf((object) => !!object?.is_change_password)
  @IsSameAs('new_password', { message: '비밀번호가 다릅니다' })
  @IsNotEmpty({ message: '비밀번호를 확인 해 주세요' })
  new_password_confirm: string;
}

export const UpdateUserFormProvider = createFormContext<UpdateUserFormValues>({
  resolver: classValidatorResolver(UpdateUserFormValues),
});
