import { IconLoadingDonuts } from '@croquiscom/pds';
import { FC, memo, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Aside } from './Aside';
import { Root } from './NavLayout.styled';
import { expand } from '@/common/styles';
import { Flex } from '@/components/common';

export const NavLayout: FC = memo(() => {
  return (
    <Root>
      <Aside />
      <Suspense
        fallback={
          <Flex css={expand(1)} alignment='center' justify='center'>
            <IconLoadingDonuts size={30} />
          </Flex>
        }
      >
        <Outlet />
      </Suspense>
    </Root>
  );
});
