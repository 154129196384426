import { colors, Divider } from '@croquiscom/pds';
import { animate, useMotionValue, useScroll, useTransform } from 'framer-motion';
import { FC, useLayoutEffect, useState } from 'react';
import { Container, Icon, Root, ToggleButton } from './Aside.styled';
import { Guidance } from './Guidance';
import { Navigation } from './Navigation';
import { Profile } from './Profile';
import { margin } from '@/common/styles';

const NAVIGATION_WITH = 210;

export const Aside: FC = () => {
  const [opened, setOpened] = useState<boolean>(true);
  const { scrollY } = useScroll();
  const width = useMotionValue<number>(NAVIGATION_WITH);
  const top = useTransform<number, number>(() => scrollY.get() + window.innerHeight / 2);

  useLayoutEffect(() => {
    animate(width, opened ? NAVIGATION_WITH : 0, {
      type: 'spring',
      bounce: 0,
      duration: 0.3,
    });
  }, [opened]);

  const handleToggleButtonClick = () => setOpened((opened) => !opened);

  return (
    <Root>
      <Container style={{ width }}>
        <Profile />
        <Divider css={margin('auto')} length='80%' />
        <Navigation />
        <Divider css={margin('auto')} length='80%' />
        <Guidance />
      </Container>
      <ToggleButton style={{ top }} onClick={handleToggleButtonClick}>
        <Icon flip={opened} size={12} color={colors.white} />
      </ToggleButton>
    </Root>
  );
};
