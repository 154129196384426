import { registerDecorator, type ValidationArguments, type ValidationOptions } from 'class-validator';

export const IsSameAs = (property: string, options?: ValidationOptions) => {
  return (object: object, propertyName: string) => {
    registerDecorator({
      name: 'isSameAs',
      target: object.constructor,
      propertyName,
      constraints: [property],
      options,
      validator: {
        validate(value: any, { constraints, object }: ValidationArguments) {
          const targetValue = (object as any)[constraints[0]];
          return value === targetValue;
        },
      },
    });
  };
};
