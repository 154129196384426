import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const LoginPage = lazy(() => import('@/pages/signin'));
const ChangePasswordPage = lazy(() => import('@/pages/signin/change-password'));
const CognitoCallbackPage = lazy(() => import('@/pages/cognito_callback'));
const SignupPage = lazy(() => import('@/pages/signup'));
const InvitePage = lazy(() => import('@/pages/signup/invite/[agencyAccountType]'));

export const auth: RouteObject[] = [
  {
    path: '/signin',
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
      {
        path: 'change-password',
        element: <ChangePasswordPage />,
      },
    ],
  },
  {
    path: '/signup',
    children: [
      {
        index: true,
        element: <SignupPage />,
      },
      {
        path: 'invite/:agencyAccountType/:token',
        element: <InvitePage />,
      },
    ],
  },
  {
    path: '/cognito_callback',
    element: <CognitoCallbackPage />,
  },
];
