import { FC, PropsWithChildren } from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider as Provider } from 'react-query';
import { BadResponseError, UnauthorizedError } from '@/common/http';

const white_list = new Set(['/signin', '/cognito_callback']);

const handleError = (error: unknown) => {
  const is_public_path = white_list.has(window.location.pathname);
  if (error instanceof UnauthorizedError && !is_public_path) {
    window.location.replace('/signin');
  }
};

const useErrorBoundary = (error: unknown) => error instanceof BadResponseError;

const client = new QueryClient({
  queryCache: new QueryCache({ onError: handleError }),
  mutationCache: new MutationCache({ onError: handleError }),
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
      useErrorBoundary,
    },
    mutations: {
      useErrorBoundary,
    },
  },
});

export const QueryClientProvider: FC<PropsWithChildren> = ({ children }) => {
  return <Provider client={client}>{children}</Provider>;
};
