import { createContext, FC, PropsWithChildren, useContext } from 'react';

const TabIdContext = createContext<string>('');

interface Props extends PropsWithChildren {
  tab_id: string;
}

export const TabIdProvider: FC<Props> = ({ tab_id, children }) => {
  return <TabIdContext.Provider value={tab_id}>{children}</TabIdContext.Provider>;
};

export const usePastelTabId = <T extends string = string>() => {
  const tab_id = useContext(TabIdContext) as T;
  return { tab_id };
};
