import { IconArrowChevronRightBold } from '@croquiscom/pds';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { flex } from '@/common/styles';

export const Root = styled.aside`
  flex: 0 0 auto;
  position: relative;
  overflow: visible;
  border-right: 1px solid ${({ theme }) => theme.palette.border.default};
  background-color: ${({ theme }) => theme.palette.background.surface};
`;

export const Container = styled(motion.nav)`
  ${flex({ direction: 'column' })}
  overflow: hidden;

  > div,
  ul {
    width: 210px;
  }
`;

export const ToggleButton = styled(motion.button)`
  ${flex({ alignment: 'center', justify: 'center' })}
  position: absolute;
  right: 0;
  transform: translate(100%, -50%);
  background-color: ${({ theme }) => theme.palette.background.scrim};
  border: none;
  z-index: 10;
  width: 18px;
  height: 40px;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
`;

export const Icon = styled(IconArrowChevronRightBold, { shouldForwardProp: isPropValid })<{
  flip?: boolean;
}>`
  transition: transform 300ms;
  transform: rotateY(${({ flip }) => (flip ? '180deg' : '0deg')});
`;
