import { GlobalStyle } from '@croquiscom/pds';
import { ThemeProvider as EmotionThemeProvider, Global } from '@emotion/react';
import { FC, PropsWithChildren } from 'react';
import { theme } from './theme';
import { fonts, normalize } from '@/common/styles';

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <EmotionThemeProvider theme={theme}>
      <Global styles={fonts} />
      <Global styles={normalize} />
      <GlobalStyle />
      {children}
    </EmotionThemeProvider>
  );
};
