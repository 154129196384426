import { BaseText, FormField, Input, semantic_colors, VStack } from '@croquiscom/pds';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { UpdateUserFormValues } from '../UpdateUserFormProvider';

const message = {
  required: '담당자 연락처를 입력 해 주세요',
  invalid: '올바른 연락처가 아닙니다',
};

export const Tel: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<UpdateUserFormValues>();
  const error = errors.tel?.message;
  return (
    <FormField label='연락처'>
      <VStack spacing={4}>
        <Input
          {...register('tel', {
            required: message.required,
            validate: (value) => isMobilePhone(value) || message.invalid,
          })}
          size='medium'
          placeholder={message.required}
          maxLength={13}
        />
        {error && (
          <BaseText kind='Caption_11_Regular' color={semantic_colors.state.negative}>
            {error}
          </BaseText>
        )}
      </VStack>
    </FormField>
  );
};
