import axios, { AxiosError, AxiosRequestHeaders, HttpStatusCode } from 'axios';
import { BadResponseError, UnauthorizedError } from './error';
import { api_base_url } from '@/common/env';

const AGENCY_ID_REGEX = /^\/agencies\/(\d+)/;

export const client = axios.create({
  baseURL: api_base_url,
});

client.interceptors.request.use((config) => {
  const [, agency_id] = window.location.pathname.match(AGENCY_ID_REGEX) ?? [];
  const headers = { ...config.headers } as AxiosRequestHeaders;
  if (agency_id) {
    headers['X-Agency-Id'] = agency_id;
  }

  return { ...config, headers };
});

client.interceptors.response.use(
  (response) => {
    if (response.headers['content-type'] === 'text/html') {
      throw new BadResponseError('BadResponse', { cause: response });
    }
    return response;
  },
  (error) => {
    if (error instanceof AxiosError && error.response?.status === HttpStatusCode.Unauthorized) {
      throw new UnauthorizedError('Unauthorized', { cause: error.response });
    }
    return Promise.reject(error);
  },
);
