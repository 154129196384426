import { Button, Divider } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Email, MfaMethod, Name, Tel, Password, NewPassword, IsChangePassword, ConfirmNewPassword } from './Input';
import { UpdateUserFormValues } from './UpdateUserFormProvider';
import { flex, mv, padding } from '@/common/styles';
import { Flex } from '@/components/common';

interface Props {
  loading?: boolean;
  disabled?: boolean;
  onSubmit?: (values: UpdateUserFormValues) => void;
  onCancel?: () => void;
}

export const Form: FC<Props> = ({ loading, disabled, onSubmit, onCancel }) => {
  const { handleSubmit } = useFormContext<UpdateUserFormValues>();

  return (
    <SC.Root onSubmit={onSubmit && handleSubmit(onSubmit)}>
      <SC.InputContainer>
        <Email />
        <Name />
        <Tel />
        <MfaMethod />
        <Password />
        <Divider css={mv(8)} />
        <IsChangePassword />
        <NewPassword />
        <ConfirmNewPassword />
      </SC.InputContainer>
      <Divider />
      <Flex css={padding('16px 24px')} alignment='center' justify='flex-end' gap={8}>
        <Button size='small' kind='outlined_secondary' loading={loading} disabled={disabled} onClick={onCancel}>
          닫기
        </Button>
        <Button type='submit' size='small' kind='primary' loading={loading} disabled={disabled}>
          수정
        </Button>
      </Flex>
    </SC.Root>
  );
};

const SC = {
  Root: styled.form`
    ${flex({ direction: 'column' })}
    position: relative;
  `,
  InputContainer: styled.div`
    ${flex({ direction: 'column', gap: 8 })}
    padding: 16px 24px;

    @supports not (gap: 8px) {
      > * + * {
        margin-top: 8px;
      }
    }
  `,
};
