import { BaseText, Dropdown, FormField, semantic_colors, VStack } from '@croquiscom/pds';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { UpdateUserFormValues } from '@/routes/layout/RootLayout/UpdateUserModal/Form';

export const MfaMethod: FC = () => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<UpdateUserFormValues>();

  const mfa = watch('mfa');
  const error = errors.mfa?.message;
  const handleChange = (value: 'SMS' | 'NONE') => setValue('mfa', value);

  return (
    <FormField label='이차인증'>
      <VStack spacing={4}>
        <Dropdown
          {...register('mfa', { required: '이차인증 방식을 선택 해 주세요' })}
          size='medium'
          value={mfa}
          onChange={handleChange}
          placeholder='이차인증 방식을 선택 해 주세요'
          options={[
            { label: '문자', value: 'SMS' },
            { label: '사용 안함', value: 'NONE' },
          ]}
        />
        {error && (
          <BaseText kind='Caption_11_Regular' color={semantic_colors.state.negative}>
            {error}
          </BaseText>
        )}
      </VStack>
    </FormField>
  );
};
