import { BaseText, FormField, semantic_colors, VStack } from '@croquiscom/pds';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { UpdateUserFormValues } from '../UpdateUserFormProvider';
import { PasswordInput } from '@/components/common';

export const Password: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<UpdateUserFormValues>();
  const error = errors.password?.message;
  return (
    <FormField label='비밀번호'>
      <VStack spacing={4}>
        <PasswordInput
          {...register('password')}
          size='medium'
          maxLength={100}
          placeholder='비밀번호를 입력 해 주세요'
        />
        {error && (
          <BaseText kind='Caption_11_Regular' color={semantic_colors.state.negative}>
            {error}
          </BaseText>
        )}
      </VStack>
    </FormField>
  );
};
