import { useQuery, useQueryClient } from 'react-query';
import { fetchUser, logout, User } from '@/api/rest';

const QUERY_KEY = 'user';
const REFETCH_INTERVAL = 1000 * 60 * 30;

export const useUser = () => {
  const client = useQueryClient();
  const {
    data,
    isLoading: is_loading,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async (): Promise<User> => fetchUser(),
    refetchInterval: REFETCH_INTERVAL,
    staleTime: REFETCH_INTERVAL,
    cacheTime: REFETCH_INTERVAL,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return {
    user: data,
    is_loading,
    refetch,
    logout: async () => {
      await logout();
      client.clear();
      await refetch();
    },
  };
};
