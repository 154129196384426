import { BaseText, colors, HStack, semantic_colors } from '@croquiscom/pds';
import { animate, clamp, motion, useMotionValue, useTransform } from 'framer-motion';
import { FC, useEffect } from 'react';
import { Root, ScoreBoard } from './Chart.styled';
import { Chip } from '@/routes/layout/NavLayout/Aside/Chart/Chip';

const RADIUS = 134 / 2; // 반지름
const ARC_LENGTH = RADIUS * Math.PI; // 호 길이
const MAX_SCORE = 10; // 최대 점수

// [1 ~ 10] -> [ARC_LENGTH ~ 2 * ARC_LENGTH]
const transform = (value: number) => clamp(ARC_LENGTH, 2 * ARC_LENGTH, (value / MAX_SCORE) * ARC_LENGTH + ARC_LENGTH);

interface Props {
  penalty_point: number;
}

export const Chart: FC<Props> = ({ penalty_point }) => {
  const strokeDashoffset = useMotionValue<number>(ARC_LENGTH);
  const stroke = useTransform(strokeDashoffset, [0, 5, 6, 8, 10].map(transform), [
    semantic_colors.state.positive,
    colors.kakao_yellow600,
    semantic_colors.state.warning,
    semantic_colors.state.negative,
    semantic_colors.state.negative,
  ]);

  useEffect(() => {
    animate(strokeDashoffset, transform(penalty_point), {
      type: 'spring',
      bounce: 0,
      duration: 0.8,
      delay: 0.5,
    });
  }, [penalty_point]);

  return (
    <Root>
      <svg width='134' height='70' viewBox='0 0 134 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M131 67C131 31.6538 102.346 3 67 3C31.6538 3 3 31.6538 3 67'
          stroke='#e0e0e4'
          strokeWidth='6'
          strokeLinecap='round'
        />
        <motion.path
          style={{ strokeDashoffset, stroke }}
          d='M131 67C131 31.6538 102.346 3 67 3C31.6538 3 3 31.6538 3 67'
          strokeWidth='6'
          strokeLinecap='round'
          strokeDasharray={ARC_LENGTH}
        />
      </svg>
      <BaseText kind='Caption_10_Regular' color={semantic_colors.content.disabled}>
        YYYY.MM.DD 기준
      </BaseText>
      <ScoreBoard style={{ color: stroke }}>
        <Chip score={penalty_point} />
        <HStack alignment='bottom'>
          <BaseText kind='Heading_17_Bold'>{penalty_point}</BaseText>
          <BaseText kind='Body_13_SemiBold'>점</BaseText>
        </HStack>
      </ScoreBoard>
    </Root>
  );
};
