import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '@/common/hooks';

export const AuthGuard: FC = () => {
  const { user } = useUser();
  if (!user) {
    return <Navigate to='/signin' replace />;
  }
  return <Outlet />;
};
