import { IconButton, IconEyeOff, IconEyeOn, Input, semantic_colors } from '@croquiscom/pds';
import { css } from '@emotion/react';
import { ComponentProps, forwardRef, useState } from 'react';

type Props = ComponentProps<typeof Input>;

export const PasswordInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [type, setType] = useState<'text' | 'password'>('password');

  const toggleType = () => setType((prev) => (prev === 'password' ? 'text' : 'password'));

  let icon = <IconEyeOff color={semantic_colors.content.tertiary} />;
  if (type === 'text') {
    icon = <IconEyeOn color={semantic_colors.content.tertiary} />;
  }

  return (
    <Input
      {...props}
      ref={ref}
      type={type}
      endElement={
        <IconButton
          css={css`
            border: none;
            width: auto;
            height: auto;
          `}
          icon={icon}
          onClick={toggleType}
        />
      }
    />
  );
});
