import { BaseText, IconColoredSiren, semantic_colors, VStack } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { FC, HTMLAttributes } from 'react';
import { Chart } from '../Chart';
import { useGetAdAgencyQuery } from '@/api';
import { useAgencyId, useUser } from '@/common/hooks';
import { display, flex } from '@/common/styles';
import { Flex, SkeletonBase, Tooltip } from '@/components/common';

export const Profile: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const { agency_id } = useAgencyId();
  const { user } = useUser();
  const { data, isLoading: is_loading } = useGetAdAgencyQuery({ id: agency_id });
  return (
    <SC.Root {...props}>
      <VStack alignment='center'>
        {is_loading && <SkeletonBase width={100} height={24} />}
        {!is_loading && <BaseText kind='Heading_17_Bold'>{data?.ad_agency?.name}</BaseText>}
        <BaseText kind='Body_13_Regular' color={semantic_colors.content.secondary}>
          {user?.identifier}
        </BaseText>
      </VStack>
      <VStack css={display('none')} alignment='center' spacing={16}>
        <VStack alignment='center' spacing={8}>
          <Flex alignment='center' gap={2}>
            <IconColoredSiren size={12} />
            <BaseText kind='Caption_11_SemiBold'>페널티 누적 점수</BaseText>
            <Tooltip
              placement='right'
              content='카카오스타일은 올바른 광고 대행 환경 구축을 위하여 대행사의 위반 영업 행위를 적발할 경우 페널티를 부여하고, 누적된 페널티 점수를 표기합니다.'
            />
          </Flex>
          {is_loading && <SkeletonBase width={134} height={88} />}
          {!is_loading && <Chart penalty_point={data?.ad_agency?.penalty_point ?? 0} />}
        </VStack>
      </VStack>
    </SC.Root>
  );
};

const SC = {
  Root: styled.div`
    ${flex({ direction: 'column', alignment: 'center', gap: 24 })}
    padding: 32px 20px;

    @supports not (gap: 24px) {
      > * + * {
        margin-top: 24px;
      }
    }
  `,
};
