import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { User } from '@/api/rest';
import { useUser } from '@/common/hooks';

interface Props {
  roles: User['role'][];
}

export const RoleGuard: FC<Props> = ({ roles }) => {
  const { user } = useUser();
  if (!user || !roles.includes(user.role)) {
    return <Navigate to='/404' replace />;
  }
  return <Outlet />;
};
