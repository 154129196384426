import { AxiosError, AxiosRequestConfig } from 'axios';
import { GraphQLError } from 'graphql';
import { http } from '@/common/http';

export interface GraphqlResponse<T> {
  data: T;
  errors?: GraphQLError[];
}

const OPERATION_NAME_REGEXP = /(?:query\s|mutation\s)(?<operation>\w+)(?=\(|\s*\{)/;

export type GqlRequestHeaders = AxiosRequestConfig['headers'];

export const gql =
  <Data, Variables>(query: string, variables?: Variables, headers?: GqlRequestHeaders) =>
  async (): Promise<Data> => {
    const operation = query.match(OPERATION_NAME_REGEXP)?.groups?.operation ?? '';
    const { data, errors } = await http<GraphqlResponse<Data>>({
      url: operation ? `/graphql/${operation}` : '/graphql',
      method: 'post',
      data: {
        query,
        variables,
      },
      headers,
    });

    const [error] = errors ?? [];
    if (error) {
      throw new GraphQLError(error.message, { ...error });
    }

    return data;
  };

export const rest = async <T>(config: AxiosRequestConfig) => {
  return http<T>(config).catch((e) => {
    const { description, error } = e.response?.data ?? {};
    if (e instanceof AxiosError && (typeof description === 'string' || typeof error === 'string')) {
      e.message = description || error;
    }
    return Promise.reject(e);
  });
};
