import { IconDocument, Menu as PdsMenu, MenuGroup, MenuItem, MenuItemProps } from '@croquiscom/pds';
import { AnchorHTMLAttributes, FC, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

interface Props extends HTMLAttributes<HTMLUListElement> {}

export const Guidance: FC<Props> = (props) => {
  const renderMenuItem = ({ id, groupId, label, icon, href, target, children }: GuidanceItemProps) => {
    if (children) {
      return (
        <MenuGroup key={id} id={id} label={label} icon={icon}>
          {children.map(renderMenuItem)}
        </MenuGroup>
      );
    }
    return (
      <Link key={id} id={id} to={href ?? '/'} target={target}>
        <MenuItem groupId={groupId} id={id} label={label} icon={icon} />
      </Link>
    );
  };

  return (
    <PdsMenu {...props} toggleMode>
      {guidance.map(renderMenuItem)}
    </PdsMenu>
  );
};

interface GuidanceItemProps extends Pick<MenuItemProps, 'id' | 'label' | 'icon'> {
  groupId?: string;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  href?: string;
  children?: GuidanceItemProps[];
}

const guidance: GuidanceItemProps[] = [
  {
    id: 'guidance-item-1',
    label: '매체 소개서',
    href: 'https://drive.google.com/file/d/10B_knAcHnAZ4QmI9PDJvcMi2x7oM2lWB/view',
    target: '_blank',
    icon: <IconDocument />,
  },
  {
    id: 'guidance-group-1',
    label: '광고 소개서',
    icon: <IconDocument />,
    children: [
      {
        id: 'guidance-item-2',
        groupId: 'guidance-group-1',
        label: '지그재그 광고',
        href: 'https://docs.google.com/presentation/d/1PLUqNbEzFColcWPfYaZ0cxsvKvcHecM01b9lN5Qp9IQ/edit#slide=id.g27431310c23_0_0',
        target: '_blank',
      },
      {
        id: 'guidance-item-3',
        groupId: 'guidance-group-1',
        label: '패션바이카카오 핫광고',
        href: 'https://docs.google.com/presentation/d/17CjpqFm5iNARr-wm6LIh6OPImbZa-LYOK4wWgith2c8/edit#slide=id.p1',
        target: '_blank',
      },
    ],
  },
  {
    id: 'guidance-group-2',
    label: '광고 운영 매뉴얼',
    icon: <IconDocument />,
    children: [
      {
        id: 'guidance-item-4',
        groupId: 'guidance-group-2',
        label: '지그재그 광고',
        href: 'https://docs.google.com/presentation/d/1KGRrNjTBtX3ecyIjvDrt3mJJxAv-OpsvRjxr8FIVqyk/edit#slide=id.g294b77c8547_0_0',
        target: '_blank',
      },
      {
        id: 'guidance-item-5',
        groupId: 'guidance-group-2',
        label: '패션바이카카오 핫광고',
        href: 'https://docs.google.com/presentation/d/1Suy0C-fqSzk90KSYF2RfeiqD9SmSa5KgyXNGuRDKxpo/edit#slide=id.p1',
        target: '_blank',
      },
    ],
  },
  {
    id: 'guidance-group-3',
    label: '광고 검수 가이드',
    icon: <IconDocument />,
    children: [
      {
        id: 'guidance-item-6',
        groupId: 'guidance-group-3',
        label: '지그재그 광고(상품형&배너형)',
        href: 'https://docs.google.com/presentation/d/1bcXuXFYKa0d_Unxzm6d5BBAdot6kjL7QijSlqDE2ptk/edit#slide=id.p1',
        target: '_blank',
      },
      {
        id: 'guidance-item-7',
        groupId: 'guidance-group-3',
        label: '패션바이카카오 핫광고',
        href: 'https://docs.google.com/presentation/d/1T7f30-bqsuWcOcyU7VA7_dHP2JJQivoPb0zm06EUwvI/edit#slide=id.p1',
        target: '_blank',
      },
    ],
  },
  {
    id: 'guidance-item-8',
    label: '대행사 어드민 이용 가이드',
    icon: <IconDocument />,
    href: 'https://cf.res.s.zigzag.kr/notice/a1f9ff8293e2401ea47c77a5e90a7423.pdf',
    target: '_blank',
  },
  {
    id: 'guidance-item-9',
    label: '대행사 정책',
    icon: <IconDocument />,
    href: 'https://cf.res.s.zigzag.kr/notice/6a23ab55c4684074aced22cf4ffca4e3.pdf',
    target: '_blank',
  },
];
