import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { agencies } from './agencies';
import { auth } from './auth';
import { AuthGuard } from '@/routes/guard';
import { RootLayout } from '@/routes/layout';

const HomePage = lazy(() => import('@/pages'));
const SSOPage = lazy(() => import('@/pages/sso'));
const NotFoundPage = lazy(() => import('@/pages/404'));
const ErrorPage = lazy(() => import('@/pages/error'));

export const root: RouteObject[] = [
  {
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <AuthGuard />,
        children: [
          {
            index: true,
            element: <HomePage />,
          },
        ],
      },
      ...agencies,
      ...auth,
      {
        path: '/sso',
        element: <SSOPage />,
      },
      {
        path: '/404',
        element: <NotFoundPage />,
      },
      {
        path: '*',
        element: <Navigate to='/404' replace />,
      },
    ],
  },
];
