import { Checkbox, FormField } from '@croquiscom/pds';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { UpdateUserFormValues } from '../UpdateUserFormProvider';

export const IsChangePassword: FC = () => {
  const { register } = useFormContext<UpdateUserFormValues>();
  return (
    <FormField label='비밀번호 변경'>
      <Checkbox {...register('is_change_password')}>비밀번호 변경</Checkbox>
    </FormField>
  );
};
