import {
  BaseText,
  Divider,
  HStack,
  IconLoadingDonuts,
  ImageKakaostyleWh,
  semantic_colors,
  TopBar,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import { FC, Suspense, useState, MouseEvent } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { UpdateUserModal } from './UpdateUserModal';
import { mode, partner_center_url } from '@/common/env';
import { useUser } from '@/common/hooks';
import { expand, mr } from '@/common/styles';
import { Flex } from '@/components/common';

export const RootLayout: FC = () => {
  const { user, is_loading, logout } = useUser();
  const navigate = useNavigate();
  const [isUpdateUserOpened, setIsUpdateUserOpened] = useState<boolean>(false);

  const openUpdateUser = () => setIsUpdateUserOpened(true);
  const closeUpdateUser = () => setIsUpdateUserOpened(false);

  const handleTitleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate('/');
  };

  return (
    <>
      <SC.Header>
        <TopBar.Title href='/' onClick={handleTitleClick}>
          <HStack alignment='center'>
            <ImageKakaostyleWh css={mr(-8)} height={18} />
            <BaseText as='h1' kind='Body_14_Regular' color={semantic_colors.content.on_color}>
              광고 대행 센터
            </BaseText>
          </HStack>
        </TopBar.Title>
        <TopBar.ActionArea>
          {user && (
            <>
              <TopBar.ActionButton>{user.identifier}</TopBar.ActionButton>
              <Divider direction='vertical' length={10} color={semantic_colors.content.on_color} />
              {!user.role.includes('zigzag') && (
                <>
                  <TopBar.ActionButton onClick={openUpdateUser}>정보수정</TopBar.ActionButton>
                  <Divider direction='vertical' length={10} color={semantic_colors.content.on_color} />
                </>
              )}
              <TopBar.ActionButton onClick={logout}>로그아웃</TopBar.ActionButton>
              <Divider direction='vertical' length={10} color={semantic_colors.content.on_color} />
            </>
          )}
          <TopBar.ActionButton>
            <Link to={`${partner_center_url}/#!/help`} target='_blank'>
              도움말
            </Link>
          </TopBar.ActionButton>
        </TopBar.ActionArea>
        {mode !== 'production' && <SC.Label>{mode}</SC.Label>}
      </SC.Header>
      <Suspense
        fallback={
          <Flex css={expand(1)} alignment='center' justify='center'>
            <IconLoadingDonuts size={30} />
          </Flex>
        }
      >
        {is_loading ? (
          <Flex css={expand(1)} alignment='center' justify='center'>
            <IconLoadingDonuts size={30} />
          </Flex>
        ) : (
          <Outlet />
        )}
      </Suspense>
      <UpdateUserModal opened={isUpdateUserOpened} close={closeUpdateUser} />
    </>
  );
};

const SC = {
  Header: styled(TopBar.Container)`
    position: sticky;
    top: 0;
    z-index: 1;
    overflow: hidden;
  `,
  Label: styled(BaseText)`
    ${({ theme }) => theme.typography.Caption_11_Bold};
    background-color: ${({ theme }) => theme.palette.accent.primary};
    color: ${({ theme }) => theme.palette.colors.white};
    display: block;
    text-align: center;
    position: absolute;
    left: -22px;
    top: 10px;
    transform: rotateZ(-45deg);
    width: 80px;
  `,
};
